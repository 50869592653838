import React from 'react';
import styled, { withTheme } from 'styled-components';
import _ from 'lodash';

import { withCssUnit } from '../utils/index';

const StyledContainer = styled(({ gutterWidth, maxWidth, ...props }) => {
  return <div {...props}>{props.children}</div>;
})`
  max-width: ${({ theme, maxWidth }) => (maxWidth ? withCssUnit(maxWidth) : _.get(theme, 'maxWidth', null))};
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  padding-right: ${({ theme, gutterWidth }) => {
    const gw = !_.isNil(gutterWidth)
      ? gutterWidth
      : _.get(theme, 'gutterWidth');
    if (gw) {
      return withCssUnit(Math.floor(gw / 2));
    }
    return 0;
  }};
  padding-left: ${({ theme, gutterWidth }) => {
    const gw = !_.isNil(gutterWidth)
      ? gutterWidth
      : _.get(theme, 'gutterWidth');
    if (gw) {
      return withCssUnit(Math.floor(gw / 2));
    }
    return 0;
  }};
`;

const Container = ({ className, theme, maxWidth, children, gutterWidth }) => {
  let finalMaxWidth = maxWidth ? maxWidth : _.get(theme, 'maxWidth', '100%');
  return (
    <StyledContainer
      className={className}
      maxWidth={finalMaxWidth}
      gutterWidth={gutterWidth}
    >
      {children}
    </StyledContainer>
  );
};

export default withTheme(Container);
